<template>
  <ItemSelector
    :field-id="fieldId"
    group="returned_items"
    :multiple="true"
    :visible-checkmark="true"
    :checked="selected"
    :disabled="!returnable"
    @on-change="select"
  >
    <p class="product__name">
      {{ name }}
    </p>
    <p class="product__quantity">
      {{ $t('Quantity: {quantity}', { quantity }) }}
    </p>
  </ItemSelector>
</template>

<script>
import ItemSelector from './ItemSelector.vue'

export default {
  name: 'ProductSelector',
  components: {
    ItemSelector,
  },
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    quantity: { type: Number, required: true },
    selected: { type: Boolean, default: false },
    returnable: { type: Boolean, default: true },
  },
  computed: {
    fieldId() {
      return `product-${this.id}`
    },
  },
  methods: {
    select(selected) {
      this.$emit('on-select', this.id, selected)
    },
  },
}
</script>
<style lang="scss" scoped>
.product__name {
  font-weight: 700;
  margin: 0;
}
.product__quantity {
  color: rgba(33, 33, 33, 0.6);
}
</style>
