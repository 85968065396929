<template>
  <TheWrapper>
    <h2
      v-if="hasNoReturnableItems"
      class="step__main-title"
    >
      {{ $t('Sorry, your items cannot be returned') }}
    </h2>
    <h2
      v-else
      class="step__main-title"
    >
      {{ $t('What would you like to return?') }}
    </h2>
    <ProductSelector
      v-for="item in returnableItems"
      :key="item.id"
      v-bind="item"
      @on-select="toggleItem"
    />
    <template v-if="nonReturnableItems.length > 0">
      <b>{{ $t('Non-returnable items') }}</b>
      <p>{{ $t('The items below cannot be returned.') }}</p>
      <ProductSelector
        v-for="item in nonReturnableItems"
        :key="`non-returnable-${item.id}`"
        :returnable="item.returnable"
        v-bind="item"
        @on-select="toggleItem"
      />
    </template>

    <UiButton
      v-if="!hasNoReturnableItems || allUrls"
      mode="primary"
      class="main-button"
    >
      <a
        v-if="hasNoReturnableItems"
        :href="allUrls"
        mode="primary"
        :style="brandStyle"
        class="main-button__btn"
        data-test="btn-return-policy"
      >
        {{ $t('Return Policy') }}
      </a>
      <button
        v-else
        class="main-button__btn"
        :disabled="disableNext"
        data-test="btn-next"
        :style="brandStyle"
        @click="next"
      >
        <span>{{ $t('Return reasons') }}</span>
        <FontAwesomeIcon :icon="$options.faChevronRight" />
      </button>
    </UiButton>
  </TheWrapper>
</template>

<script>
import { APP_SELECT_RETURNED_ITEMS } from '@/store/action.types'
import { brandColourMixin, allUrls } from '@/components/mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { mapState } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import ProductSelector from '@/components/common/ProductSelector.vue'
import TheWrapper from '@/components/layout/TheWrapper'

export default {
  name: 'ReturnsItemSelectionView',
  faChevronRight,
  components: {
    FontAwesomeIcon,
    UiButton,
    TheWrapper,
    ProductSelector,
  },
  mixins: [brandColourMixin, allUrls],
  data() {
    return {
      selectedItems: [],
    }
  },
  computed: {
    ...mapState({
      returnedItems: (state) => state.returnedItems,
      packingItems: (state) => state.packingItems,
    }),

    /**
     * @return {Array<Object>} list of items to return
     */
    items() {
      return this.packingItems.map((item) => {
        const selected = this.selectedItems.includes(item.id)
        return { ...item, selected }
      })
    },

    returnableItems() {
      return this.items.filter((item) => item.returnable !== false)
    },

    nonReturnableItems() {
      return this.items.filter((item) => item.returnable === false)
    },

    disableNext() {
      return this.selectedItems.length === 0 && this.returnableItems.length !== 0
    },

    hasNoReturnableItems() {
      return this.returnableItems.length === 0
    },
  },
  created() {
    this.selectedItems = this.returnedItems.map((x) => x.id)
  },
  methods: {
    async next() {
      if (!this.selectedItems) return
      await this.$store.dispatch(APP_SELECT_RETURNED_ITEMS, this.selectedItems)
      const params = { item: this.returnedItems[0].id }
      this.$router.push({ name: 'return-reasons', params }).catch(handleRouteRejection)
    },
    toggleItem(id, selected) {
      if (selected && !this.selectedItems.includes(id)) {
        this.selectedItems.push(id)
      } else if (!selected && this.selectedItems.includes(id)) {
        this.selectedItems = this.selectedItems.filter((x) => x !== id)
      }
    },
  },
}
</script>
